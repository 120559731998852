import React from 'react';

export default function YouTube(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 0.0214844C8.95431 0.0214844 0 8.97579 0 20.0215C0 31.0672 8.95431 40.0215 20 40.0215C31.0457 40.0215 40 31.0672 40 20.0215C40 8.97579 31.0457 0.0214844 20 0.0214844ZM28.3348 13.1458C29.2528 13.3976 29.9757 14.1398 30.221 15.0823C30.6668 16.7905 30.6668 20.3547 30.6668 20.3547C30.6668 20.3547 30.6668 23.9187 30.221 25.627C29.9757 26.5695 29.2528 27.3117 28.3348 27.5637C26.6713 28.0213 20.0002 28.0213 20.0002 28.0213C20.0002 28.0213 13.329 28.0213 11.6654 27.5637C10.7474 27.3117 10.0245 26.5695 9.77919 25.627C9.3335 23.9187 9.3335 20.3547 9.3335 20.3547C9.3335 20.3547 9.3335 16.7905 9.77919 15.0823C10.0245 14.1398 10.7474 13.3976 11.6654 13.1458C13.329 12.688 20.0002 12.688 20.0002 12.688C20.0002 12.688 26.6713 12.688 28.3348 13.1458Z"
        fill="#4889AD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 24.0216V17.355L23.3333 20.6884L18 24.0216Z"
        fill="#4889AD"
      />
    </svg>
  );
}
