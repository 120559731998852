import React from 'react';

export default function Instagram(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 0.0214844C8.95431 0.0214844 0 8.97579 0 20.0215C0 31.0672 8.95431 40.0215 20 40.0215C31.0457 40.0215 40 31.0672 40 20.0215C40 8.97579 31.0457 0.0214844 20 0.0214844ZM15.6029 9.41943C16.7407 9.36765 17.1042 9.35498 20.0012 9.35498H19.9978C22.8956 9.35498 23.2579 9.36765 24.3957 9.41943C25.5312 9.47143 26.3068 9.65121 26.9868 9.91499C27.689 10.1872 28.2824 10.5517 28.8757 11.145C29.469 11.7379 29.8335 12.333 30.1068 13.0346C30.369 13.7128 30.5491 14.4879 30.6024 15.6235C30.6535 16.7613 30.6668 17.1248 30.6668 20.0218C30.6668 22.9187 30.6535 23.2813 30.6024 24.4191C30.5491 25.5543 30.369 26.3296 30.1068 27.0081C29.8335 27.7094 29.469 28.3045 28.8757 28.8974C28.283 29.4907 27.6888 29.8561 26.9875 30.1285C26.3088 30.3923 25.5328 30.5721 24.3972 30.6241C23.2594 30.6759 22.897 30.6885 19.9998 30.6885C17.1031 30.6885 16.7398 30.6759 15.602 30.6241C14.4667 30.5721 13.6913 30.3923 13.0126 30.1285C12.3115 29.8561 11.7164 29.4907 11.1237 28.8974C10.5306 28.3045 10.1662 27.7094 9.8935 27.0078C9.62994 26.3296 9.45016 25.5545 9.39794 24.4189C9.34639 23.2811 9.3335 22.9187 9.3335 20.0218C9.3335 17.1248 9.34683 16.7611 9.39772 15.6233C9.44883 14.4881 9.62883 13.7128 9.89328 13.0344C10.1666 12.333 10.5311 11.7379 11.1244 11.145C11.7173 10.5519 12.3124 10.1874 13.014 9.91499C13.6922 9.65121 14.4673 9.47143 15.6029 9.41943Z"
        fill="#4889AD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0445 11.2771C19.2303 11.2768 19.4302 11.2769 19.6459 11.277L20.0014 11.2771C22.8494 11.2771 23.187 11.2873 24.3116 11.3384C25.3516 11.386 25.9161 11.5598 26.2921 11.7058C26.7899 11.8991 27.1448 12.1302 27.5179 12.5035C27.8912 12.8769 28.1223 13.2324 28.3161 13.7302C28.4621 14.1058 28.6361 14.6702 28.6835 15.7102C28.7346 16.8347 28.7457 17.1725 28.7457 20.0192C28.7457 22.8659 28.7346 23.2037 28.6835 24.3281C28.6359 25.3681 28.4621 25.9326 28.3161 26.3081C28.1228 26.8059 27.8912 27.1604 27.5179 27.5335C27.1446 27.9068 26.7901 28.1379 26.2921 28.3313C25.9165 28.4779 25.3516 28.6513 24.3116 28.6988C23.1872 28.7499 22.8494 28.761 20.0014 28.761C17.1531 28.761 16.8156 28.7499 15.6911 28.6988C14.6511 28.6508 14.0866 28.477 13.7104 28.331C13.2126 28.1377 12.8571 27.9066 12.4837 27.5333C12.1104 27.1599 11.8793 26.8052 11.6855 26.3072C11.5395 25.9317 11.3655 25.3672 11.3182 24.3272C11.2671 23.2028 11.2568 22.865 11.2568 20.0165C11.2568 17.168 11.2671 16.832 11.3182 15.7076C11.3657 14.6676 11.5395 14.1031 11.6855 13.7271C11.8788 13.2293 12.1104 12.8738 12.4837 12.5004C12.8571 12.1271 13.2126 11.896 13.7104 11.7022C14.0864 11.5555 14.6511 11.3822 15.6911 11.3344C16.6751 11.29 17.0565 11.2766 19.0445 11.2744V11.2771ZM25.6951 13.0483C24.9884 13.0483 24.415 13.621 24.415 14.3279C24.415 15.0345 24.9884 15.6079 25.6951 15.6079C26.4017 15.6079 26.9751 15.0345 26.9751 14.3279C26.9751 13.6212 26.4017 13.0479 25.6951 13.0479V13.0483ZM14.5234 20.0213C14.5234 16.9962 16.976 14.5436 20.0011 14.5435C23.0262 14.5435 25.4782 16.9961 25.4782 20.0213C25.4782 23.0464 23.0264 25.498 20.0013 25.498C16.9761 25.498 14.5234 23.0464 14.5234 20.0213Z"
        fill="#4889AD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0009 16.4663C21.9645 16.4663 23.5565 18.0581 23.5565 20.0219C23.5565 21.9855 21.9645 23.5775 20.0009 23.5775C18.0371 23.5775 16.4453 21.9855 16.4453 20.0219C16.4453 18.0581 18.0371 16.4663 20.0009 16.4663V16.4663Z"
        fill="#4889AD"
      />
    </svg>
  );
}
