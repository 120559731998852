/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { Link as ScrollLink } from 'react-scroll';

const Link = ({
  to,
  title,
  type = 'internal',
  children,
  className,
  ...props
}) => {
  return type === 'internal' ? (
    <ScrollLink
      className={className}
      to={to}
      spy
      smooth
      offset={-100}
      duration={700}
      title={title}
      href=""
      {...props}
    >
      {children}
    </ScrollLink>
  ) : (
    <GatsbyLink to={to} className={className} {...props}>
      {children}
    </GatsbyLink>
  );
};

export default Link;
