import React from 'react';
import PropTypes from 'prop-types';

const FaqItem = ({
  handleClick,
  question,
  index,
  activeAnswerIndex,
  children,
}) => {
  const isActive = activeAnswerIndex === index;
  return (
    <div className="bg-white rounded-3xl p-4 mb-2 lg:mb-8">
      <div
        className="flex flex-row justify-between items-center cursor-pointer focus:outline-none"
        onClick={handleClick}
        onKeyDown={handleClick}
        role="button"
      >
        <h4 className="my-1 font-bold text-xl text-primary-600">{question}</h4>
        <button type="button" className="bg-primary-main p-4 rounded-full">
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              transform: isActive ? 'rotate(0deg)' : 'rotate(180deg)',
            }}
          >
            <path
              d="M1.175 6.31822L5 2.50155L8.825 6.31822L10 5.14322L5 0.143219L0 5.14322L1.175 6.31822Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
      {isActive && <div>{children}</div>}
    </div>
  );
};

FaqItem.propTypes = {
  question: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  activeAnswerIndex: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default FaqItem;
