import React from 'react';

const HamburgerMenu = ({ show }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="46"
      viewBox="0 0 42 42"
      className="stroke-white"
    >
      {show ? (
        <path
          d="M26.8327 16.3415L25.6577 15.1665L20.9993 19.8248L16.341 15.1665L15.166 16.3415L19.8243 20.9998L15.166 25.6582L16.341 26.8332L20.9993 22.1748L25.6577 26.8332L26.8327 25.6582L22.1743 20.9998L26.8327 16.3415Z"
          className="fill-white"
        />
      ) : (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.5 17.6667V16H28.5V17.6667H13.5ZM13.5 21.8333H28.5V20.1667H13.5V21.8333ZM13.5 26H28.5V24.3333H13.5V26Z"
          className="fill-white"
        />
      )}
    </svg>
  );
};

export default HamburgerMenu;
