import React from 'react';

export default function Facebook(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 0.0214844C8.95431 0.0214844 0 8.97579 0 20.0215C0 31.0672 8.95431 40.0215 20 40.0215C31.0457 40.0215 40 31.0672 40 20.0215C40 8.97579 31.0457 0.0214844 20 0.0214844ZM22.0848 20.9V31.781H17.5827V20.9004H15.3335V17.1507H17.5827V14.8995C17.5827 11.8405 18.8528 10.0215 22.4612 10.0215H25.4652V13.7716H23.5875C22.1828 13.7716 22.0899 14.2956 22.0899 15.2736L22.0848 17.1503H25.4865L25.0884 20.9H22.0848Z"
        fill="#4889AD"
      />
    </svg>
  );
}
