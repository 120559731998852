import React, { useState } from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import clsx from 'clsx';
import HamburgerMenu from './HamburgerMenu';
import Link from './Link';
import HomeNav from './HomeNav';

const Header = ({location}) => {
  const [show, setShow] = useState(false);
  const { logo } = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 657, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  const headerClass = clsx('fixed w-full z-50 bg-white top-0', {
    'h-full': show
  });

  return (
    <header className={headerClass}>
      <div className="flex flex-col h-full xl:flex-col-reverse">
        <div className="xl:container flex-grow flex flex-col xl:flex-row xl:items-center h-full">
          <div className="w-full xl:w-1/6 bg-blue-500 xl:bg-inherit">
            <div className="flex flex-row justify-between">
              <Link className="w-full" to="/" type="external">
                <Img
                  fluid={logo.childImageSharp.fluid}
                  alt="Evita"
                  style={{ maxWidth: 90 }}
                />
              </Link>
              <button
                type="button"
                onClick={() => setShow(!show)}
                className="outline-none xl:hidden pr-6"
              >
                <HamburgerMenu show={show} />
              </button>
            </div>
          </div>

          <HomeNav show={show} setShow={setShow} location={location} />
        </div>
      </div>
    </header>
  );
};;

export default Header;
