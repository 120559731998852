/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { APP_LOGIN_URL, APP_REGISTER_URL, SHOW_APP_URL } from '../../constants';
import Link from './Link';

const HomeNav = ({ show, setShow, location }) => {
  const StyledLink = ({ to, title }) => {
    const isScrollNav = location.pathname === '/';

    return (
      <Link
        to={isScrollNav ? to : `/#${to}`}
        title={title}
        type={isScrollNav ? 'internal' : 'gatsby'}
        className="py-2 px-3 text-white xl:text-primary-main text-lg xl:text-base xl:uppercase"
        onClick={() => setShow(false)}
      >
        {title}
      </Link>
    );
  };

  const navClass = clsx(
    'flex xl:flex flex-col xl:flex-row items-start xl:items-center justify-center xl:justify-end w-full xl:w-5/6 h-full bg-primary-main xl:bg-inherit',
    { hidden: !show }
  );

  return (
    <nav className={navClass}>
      <div className="w-full h-full flex flex-col xl:flex-row xl:justify-end justify-center items-center">
        <StyledLink to="intro" title="Kezdőlap" />
        <StyledLink to="steps" title="Hogyan működik?" />
        <StyledLink to="why" title="Miért regisztráljak?" />
        <StyledLink to="partners" title="Partnerek" />
        <StyledLink to="faq" title="GY.I.K." />
        <StyledLink to="donate" title="Adományozás" />
      </div>
      {SHOW_APP_URL && (
        <button
          type="button"
          className="p-3 pt-0 xl:pl-3 xl:pb-0 w-full xl:w-auto mt-auto xl:mt-0"
        >
          <a
            href={APP_LOGIN_URL}
            target="_blank"
            className="btn-outlined text-white bg-primary-main xl:text-primary-main xl:bg-white w-full max-w-[400px] xl:w-auto"
          >
            Bejelentkezés
          </a>
        </button>
      )}
      {SHOW_APP_URL && (
        <button
          type="button"
          className="p-3 pt-0 xl:pl-0 xl:pb-0 w-full xl:w-auto"
        >
          <a
            href={APP_REGISTER_URL}
            target="_blank"
            className="btn text-primary-main bg-white xl:text-white xl:bg-primary-main w-full max-w-[400px] xl:w-auto"
          >
            Regisztráció
          </a>
        </button>
      )}
    </nav>
  );
};

HomeNav.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default HomeNav;
