import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Link from './Link';
import adatvedelem from '../../documents/adatvedelem.pdf';
import Facebook from '../icons/Facebook';
import Instagram from '../icons/Instagram';
import YouTube from '../icons/YouTube';

const Footer = () => {
  const { logo, poweredBy } = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 348, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      poweredBy: file(relativePath: { eq: "powered_by.png" }) {
        childImageSharp {
          fluid(maxWidth: 178, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <footer className="bg-[#e9f3fe66]">
      <div className="container flex flex-col md:flex-row container px-4 py-8 md:py-16 md:items-center justify-between">
        <div className="max-w-[250px] pb-12 md:pb-0">
          <Link to="/">
            <Img
              fluid={logo.childImageSharp.fluid}
              alt="Evita"
              style={{ maxHeight: 84, width: 170 }}
            />
          </Link>
          <p className="text-primary-400 uppercase text-xs pt-4">
            Az Evita digitális szolgáltatásának tartalma a Magyar Ifjúsági
            Konferencia kizárólagos tulajdonát képezi.
          </p>
        </div>
        <div className="flex flex-col md:flex-row justify-between">
          <div className="px-0 md:px-12 pb-12 md:pb-0">
            <p className="pb-2 text-sm text-primary-700 uppercase">
              Menüpontok
            </p>
            <ol className="list-none">
              <li className="pb-2">
                <Link to="intro" className="footer-menu">
                  Kezdőlap
                </Link>
              </li>
              <li className="pb-2">
                <Link to="steps" className="footer-menu">
                  Hogyan működik?
                </Link>
              </li>
              <li className="pb-2">
                <Link to="why" className="footer-menu">
                  Miért regisztráljak?
                </Link>
              </li>
              <li className="pb-2">
                <Link to="partners" className="footer-menu">
                  Partnerek
                </Link>
              </li>
              <li>
                <Link to="faq" className="footer-menu">
                  GY.I.K.
                </Link>
              </li>
            </ol>
          </div>
          <div className="px-0 md:px-12">
            <p className="pb-2 text-sm text-primary-700 uppercase">
              Kontakt információk
            </p>
            <ol className="list-none">
              <li className="contact-menu">
                <a href="mailto:titkarsag@mik.ma">titkarsag@mik.ma</a>
              </li>
              <li className="contact-menu">
                1055 Budapest, Markó utca 7. VI/9.
              </li>
              <li className="contact-menu">+36 70 361 3766</li>
            </ol>
            <div className="flex flex-row">
              <a
                href="https://www.facebook.com/magyarifjusagikonferencia"
                target="_blank"
              >
                <Facebook className="mx-1" width="40" height="41" />
              </a>
              <a
                href="https://www.instagram.com/magyar_ifjusagi_konferencia/"
                target="_blank"
              >
                <Instagram className="mx-1" width="40" height="41" />
              </a>
              <YouTube className="mx-1" width="40" height="41" />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center py-6">
        <Img
          fluid={poweredBy.childImageSharp.fluid}
          alt="Powered by"
          style={{ width: 177 }}
        />
      </div>
      <div className="flex bg-primary-400 px-4">
        <div className="flex container align-center justify-center flex-col sm:flex-row">
          <a
            href={adatvedelem}
            name="Adatkezelési tájékoztató"
            target="_blank"
            className="inline-block uppercase text-white py-3 pl-6 hover:underline text-sm text-center"
          >
            Adatkezelési tájékoztató
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
