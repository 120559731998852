import React from 'react';
import PropTypes from 'prop-types';

import SEO from './SEO';
import Footer from './Footer';
import Header from './Header';

const Layout = ({ children, seo, baseRoute, location }) => {
  const { lang, title, description } = seo;

  return (
    <>
      <SEO lang={lang} title={title} description={description} />
      <Header baseRoute={baseRoute} location={location} />
      <main className="mt-[4.3rem] lg:mt-[4.4rem] overflow-x-hidden">{children}</main>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  baseRoute: PropTypes.string.isRequired,
  seo: PropTypes.shape({
    lang: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }).isRequired
};

export default Layout;
